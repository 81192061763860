<script>
import MessagingNode from "./BaseMessagingNode";
import { NODE_TYPES } from "@/constants/nodes";

export default {
  mixins: [MessagingNode],
  created() {
    this.$set(this.nodeToBind, "node_type", NODE_TYPES.SEND_SMS.NODE_TYPE);
    this.$set(this.nodeToBind.messaging_node.data, "sms_node_type", "send");
  }
};
</script>
