<template>
  <div class="live-call-monitoring">
    <el-row :gutter="20" class="header">
      <el-col :span="22" class="title">
        <div class="user-display-name">
          {{ __("Live Call Monitoring") }}
        </div>
      </el-col>
      <el-col :span="2" class="actions">
        <el-button
          icon="el-icon-close"
          type="text"
          @click="handleCloseLive"
        ></el-button>
      </el-col>
    </el-row>
    <el-form
      ref="callflowForm"
      :model="callflowForm"
      :rules="rules"
      size="small"
      label-position="left"
      label-width="50px"
    >
      <el-form-item label="ANI" prop="ani">
        <el-input
          v-model="callflowForm.ani"
          :placeholder="__('Enter ANI')"
        ></el-input>
      </el-form-item>
      <el-form-item label="DNIS" prop="dnis">
        <el-select
          v-model="callflowForm.dnis"
          :placeholder="__('Select DNIS')"
          filterable
        >
          <el-option
            v-for="item in phoneNumber"
            :key="item['phone_number']"
            :label="item['phone_number']"
            :value="item['phone_number']"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          ref="monitorCallButton"
          @click="onSubmit"
          :disabled="monitorCallButtonDisabled"
          :style="{
            backgroundColor: monitorCallButtonDisabled ? 'green' : '',
            borderColor: monitorCallButtonDisabled ? 'green' : ''
          }"
        >
          {{
            monitorCallButtonDisabled
              ? __("Monitoring Call")
              : __("Monitor Call")
          }}
        </el-button>
      </el-form-item>
    </el-form>
    <section v-show="isShown" style="overflow-y: scroll">
      <div
        class="body"
        style="overflow-y: hidden"
        v-loading="isTaskLoading"
        :element-loading-text="__('Awaiting Active Call...')"
      >
        <el-collapse ref="liveCallMonitoringCollapseAccordion" accordion>
          <el-collapse-item
            v-for="(session, index) in sessionsOnDisplay"
            :key="index"
            :name="session.session_id"
          >
            <template slot="title">
              <!-- <div class="phone">ANI: {{ session["ani"] }}</div>
              <div class="phone">DNIS: {{ session["phone_number"] }}</div> -->
              <div
                class="phone"
                style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
              >
                Session ID: {{ session["session_id"] }}
              </div>

              <div class="timestamp">
                {{ getTimeGap(session["created_at"]) }}
              </div>
              <el-select
                v-show="session.session_id === currentSessionId && !isOnLive"
                v-model="selectNodeId"
                class="select-node-detail"
                size="small"
                filterable
                :placeholder="__('Select Node Detail')"
              >
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                <el-option
                  v-for="item in nodeDetails"
                  :key="item.node_id"
                  :label="item.node_name"
                  :value="item.node_id"
                  ><div style="display: flex; align-items: center;">
                    <div
                      style="
                        height: 16px;
                        width: 16px;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <img
                        v-if="item.node_type !== 'start_node'"
                        :src="getIconForNode(item.node_type)"
                        height="16px"
                        width="16px"
                      />
                      <img
                        v-else
                        class="svg-icon"
                        :src="getIconForNode('voice_start_node')"
                        height="16px"
                        width="16px"
                        onload="SVGInject(this)"
                      />
                    </div>
                    <div>
                      {{ item.node_name }}
                    </div>
                  </div>
                </el-option>
              </el-select>
              <el-switch
                v-show="
                  session.session_id === currentSessionId &&
                    !isCallLoading(session.session_id)
                "
                class="switch"
                v-model="isOnLive"
                :active-text="__('Live On')"
                :inactive-text="__('Live Off')"
                @change="toggleLive"
                @click.stop.native
              ></el-switch>
            </template>
            <div class="call-detail" ref="detail" :id="session.session_id">
              <div
                v-loading="isCallLoading(session.session_id)"
                :element-loading-text="__('Fetching Call Status...')"
                element-loading-spinner="el-icon-loading"
              >
                <div
                  style="display: flex; justify-content: center; padding-bottom: 20px;"
                >
                  <el-radio-group
                    v-model="simpleOrAdvancedMode"
                    v-if="!isCallLoading(session.session_id)"
                  >
                    <el-radio-button label="simple">Simple</el-radio-button>
                    <el-radio-button label="advanced">Advanced</el-radio-button>
                  </el-radio-group>
                </div>
                <div
                  v-if="
                    simpleOrAdvancedMode === 'simple' &&
                      !isCallLoading(session.session_id)
                  "
                >
                  <div
                    v-for="(log, index) in logs[session.session_id.toString()]"
                    :key="index"
                  >
                    <live-call-transcription :log="log" />
                  </div>
                </div>
                <div
                  v-if="
                    simpleOrAdvancedMode === 'advanced' &&
                      !isCallLoading(session.session_id)
                  "
                >
                  <el-timeline
                    v-if="
                      simpleOrAdvancedMode === 'advanced' &&
                        !isCallLoading(session.session_id)
                    "
                  >
                    <el-timeline-item
                      v-for="(log, index) in logsWithoutStudioConversation(
                        session.session_id
                      )"
                      :key="index"
                      :hide-timestamp="true"
                      class="node-event"
                    >
                      <div
                        v-if="
                          log.event !== 'NODE_LOG' &&
                            log.event !== 'STUDIO_CONVERSATION'
                        "
                      >
                        <div>
                          <b class="log-event">{{ toTitleCase(log.event) }}</b>
                        </div>
                        <div v-if="log.message">
                          {{ log.message }}
                        </div>
                        <div v-if="log.log_parameters.node_name">
                          <div class="node-name">
                            <img
                              v-if="
                                log.log_parameters.node_type !== 'start_node'
                              "
                              class="node-icon"
                              :src="
                                getIconForNode(log.log_parameters.node_type)
                              "
                            />
                            <img
                              v-else
                              class="node-icon svg-icon"
                              :src="getIconForNode('voice_start_node')"
                              onload="SVGInject(this)"
                            />
                            <span>{{ log.log_parameters.node_name }}</span>
                          </div>
                        </div>
                        <br />
                      </div>
                      <div v-if="log.event == 'NODE_LOG'">
                        <node-variable-log
                          :nodelog="formatLogNode(log)"
                          :nodeName="log.log_parameters.payload.node_name"
                        ></node-variable-log>
                        <br />
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment-timezone";
import _ from "lodash";
import NodeVariableLog from "./NodeVariableLog.vue";
import LiveCallTranscription from "./LiveCallTranscription.vue";

export default {
  components: {
    NodeVariableLog,
    LiveCallTranscription
  },
  props: {
    taskId: {
      required: true
    },
    phoneNumber: {
      required: true
    }
  },
  data() {
    return {
      isOnLive: true,
      simpleOrAdvancedMode: "simple",
      isShown: false,
      logs: {},
      sessions: [],
      currentSessionId: "",
      callflowForm: {
        ani: "",
        dnis: ""
      },
      rules: {
        ani: [
          { required: true, message: "Please enter ANI", trigger: "blur" },
          {
            pattern: /^\d+$/,
            message: "Please enter a valid numeric value",
            trigger: "blur"
          }
        ],
        dnis: [
          { required: true, message: "Please select DNIS", trigger: "change" }
        ]
      },
      activeNodeId: "1",
      pausedNodeId: null,
      pausedNodeEvent: null,
      selectNodeId: null,
      liveCallTaskChannel: ""
    };
  },
  computed: {
    ...mapState("app", {
      token: state => state.token
    }),

    ...mapState("activecalls", {
      activeCalls: state => state.activeCalls
    }),

    liveCallSessionChannel() {
      return `live_call_monitoring.${this.currentSessionId}`;
    },

    monitorCallButtonDisabled() {
      if (this.liveCallTaskChannel) {
        let liveCallTaskChannelDetails = this.liveCallTaskChannel.split(".");
        let ani = liveCallTaskChannelDetails[2];
        let dnis = liveCallTaskChannelDetails[3];
        if (this.callflowForm.ani === ani && this.callflowForm.dnis === dnis) {
          return true;
        }
      }
      return false;
    },

    isTaskLoading() {
      return !this.sessions.length > 0;
    },

    sessionsOnDisplay() {
      return this.sessions.slice().reverse();
    },
    pauseOrActive() {
      return this.selectNodeId || this.pausedNodeId || this.activeNodeId;
    },
    nodeDetails() {
      let nodes = [];
      this.logs[this.currentSessionId.toString()].forEach(log => {
        if (log.event === "PROCESS_NODE" && log.log_parameters) {
          nodes.push({
            node_id: log.log_parameters.node_id,
            node_name: log.log_parameters.node_name,
            node_type: log.log_parameters.node_type
          });
        }
        if (log.event === "LOG_NODE" && log.log_parameters.payload) {
          nodes.push({
            node_id: log.log_parameters.payload.node_id,
            node_name: log.log_parameters.payload.node_name,
            node_type: log.log_parameters.payload.node_type
          });
        }
      });
      return _.uniqWith(nodes, _.isEqual);
    }
  },

  watch: {
    pauseOrActive(newVal) {
      this.$emit("updateActiveNode", newVal);
    },
    logs() {
      if (!this.pausedNodeId) {
        this.$nextTick(() => {
          // auto scroll to bottom when new log comes in
          this.$refs.detail.forEach(el => {
            el.scrollTop = el.scrollHeight;
          });
        });
      }
    },
    pausedNodeId(newVal) {
      if (newVal) {
        let session = document.getElementById(this.currentSessionId);
        let nodeEvents = session.querySelectorAll(".node-event");
        this.pausedNodeEvent = nodeEvents[nodeEvents.length - 1];
        this.pausedNodeEvent.classList.add("highlight");
      } else {
        this.pausedNodeEvent.classList.remove("highlight");
      }
    },
    selectNodeId(newVal) {
      if (newVal) {
        this.pausedNodeEvent.classList.remove("highlight");
        let index = this.logsWithoutStudioConversation(
          this.currentSessionId
        ).findIndex(
          log =>
            log.event === "LOG_NODE" &&
            log.log_parameters.payload &&
            log.log_parameters.payload.node_id === newVal
        );
        if (index === -1) {
          index = this.logsWithoutStudioConversation(
            this.currentSessionId
          ).findIndex(
            log =>
              log.event === "PROCESS_NODE" &&
              log.log_parameters &&
              log.log_parameters.node_id === newVal
          );
        }
        if (index >= 0) {
          let session = document.getElementById(this.currentSessionId);
          let nodeEvents = session.querySelectorAll(".node-event");
          // nagivate to the specific node detail based on its offsetTop value
          session.scrollTop = nodeEvents[index].offsetTop - 60;
          this.pausedNodeEvent = nodeEvents[index];
          this.pausedNodeEvent.classList.add("highlight");
        } else {
          this.pausedNodeEvent.classList.remove("highlight");
        }
      } else {
        this.pausedNodeEvent.classList.remove("highlight");
      }
    }
  },

  methods: {
    ...mapActions("activecalls", {
      getActiveCalls: "getActiveCalls"
    }),

    logsWithoutStudioConversation(session_id) {
      const sessionLogs = this.logs[session_id.toString()];
      return sessionLogs.filter(log => log.event !== "STUDIO_CONVERSATION");
    },

    getTimeGap(timestamp) {
      return moment(timestamp).fromNow();
    },

    isCallLoading(session_id) {
      return !this.logs[session_id].length > 0;
    },

    convertTimestamp(timestamp) {
      return moment(parseInt(timestamp) / 1000).format("HH:mm:ss");
    },

    handleCloseLive() {
      this.$emit("hideDetails");
    },

    getIconForNode(type) {
      return "/icons/" + type + ".svg";
    },

    joinLiveCallTaskChannel() {
      if (this.$echo && this.$echo.connector.socket.connected && this.token) {
        this.$echo.connector.options.auth.headers.Authorization =
          "Bearer " + this.token;
        this.$echo
          .join(this.liveCallTaskChannel)
          .listen("CallMonitoringTaskEvent", e => {
            if (e.call_type === "CREATE_CALL") {
              this.sessions.push(e.active_call_data);
              if (this.currentSessionId) {
                this.leaveLiveCallSessionChannel();
              }
              this.currentSessionId = e.active_call_data.session_id;
              this.$set(
                this.logs,
                e.active_call_data.session_id.toString(),
                []
              );
              this.joinLiveCallSessionChannel();
            } else {
              this.logs[this.currentSessionId.toString()].push({
                event: "CALL_ENDED",
                message: "",
                log_parameters: {
                  timestamp: Date.now(),
                  session_id: e.active_call_data.session_id
                }
              });
            }
          })
          .error(error => {
            console.error(error);
          });
      }
    },

    leaveLiveCallTaskChannel() {
      this.sessions = [];
      this.logs = {};
      this.$echo.leave(this.liveCallTaskChannel);
    },

    joinLiveCallSessionChannel() {
      if (this.$echo && this.$echo.connector.socket.connected && this.token) {
        this.$echo.connector.options.auth.headers.Authorization =
          "Bearer " + this.token;
        this.$echo
          .join(this.liveCallSessionChannel)
          .listen("CallMonitoringEvent", e => {
            this.logs[this.currentSessionId.toString()].push(e);
            this.logs[this.currentSessionId.toString()].sort((a, b) => {
              let aNodeOrder = this.getEventNodeOrder(a);
              let bNodeOrder = this.getEventNodeOrder(b);
              if (aNodeOrder != null && bNodeOrder != null) {
                if (aNodeOrder !== bNodeOrder) {
                  return aNodeOrder - bNodeOrder;
                }
              }
              return a.log_parameters.timestamp - b.log_parameters.timestamp;
            });
            this.updateActiveNodeId(e);
          })
          .error(error => {
            console.error(error);
          });
      }
    },

    getEventNodeOrder(event) {
      if (event.event === "PROCESS_NODE") {
        if (event.log_parameters.node_order != null) {
          return event.log_parameters.node_order;
        }
      }

      if (event.event === "NODE_LOG") {
        if (event.log_parameters.payload.node_order != null) {
          return event.log_parameters.payload.node_order;
        }
      }
      return null;
    },

    leaveLiveCallSessionChannel() {
      this.$echo.leave(this.liveCallSessionChannel);
      // this.logs = {};
      this.activeNodeId = "1";
      this.pausedNodeId = null;
      this.pausedNodeName = null;
      this.selectNodeId = null;
      this.currentSessionId = "";
      this.isOnLive = true;
    },

    updateActiveNodeId(e) {
      if (e.event === "PROCESS_NODE" && e.log_parameters.node_id) {
        this.activeNodeId = e.log_parameters.node_id;
      } else if (
        e.event === "LOG_NODE" &&
        e.log_parameters.payload &&
        e.log_parameters.payload.node_id
      ) {
        this.activeNodeId = e.log_parameters.payload.node_id;
      }
    },

    toTitleCase(str) {
      str = str.replaceAll("_", " ");
      if (str.split(" ").length < 2) {
        return str;
      }
      return _.startCase(_.toLower(str));
    },

    replacer(key, value) {
      if (key === "variables") {
        return undefined;
      }
      return value;
    },

    formatLogNode(log) {
      if (
        log.log_parameters.payload &&
        log.log_parameters.payload.node_values &&
        log.log_parameters.payload.node_values.form_data
      ) {
        return JSON.stringify(
          log.log_parameters.payload.node_values.form_data,
          null,
          2
        );
      } else if (
        log.log_parameters.payload &&
        log.log_parameters.payload.node_values
      ) {
        return JSON.stringify(
          log.log_parameters.payload.node_values,
          this.replacer,
          2
        );
      } else {
        return JSON.stringify(log.log_parameters, null, 2);
      }
    },
    toggleLive() {
      if (!this.isOnLive) {
        this.pausedNodeId = this.activeNodeId;
      } else {
        this.pausedNodeId = null;
        this.selectNodeId = null;
      }
    },
    onSubmit() {
      this.$refs["callflowForm"].validate(async valid => {
        if (valid) {
          // leave the previous task channel and join a new one
          if (this.liveCallTaskChannel) {
            this.leaveLiveCallTaskChannel();
          }
          if (this.liveCallSessionChannel) {
            this.leaveLiveCallSessionChannel();
          }
          this.isShown = true;
          this.liveCallTaskChannel = `live_call_task_monitoring.${this.taskId}.${this.callflowForm.ani}.${this.callflowForm.dnis}`;
          this.joinLiveCallTaskChannel();
          await this.getActiveCalls("ac");
          this.sessions = this.activeCalls.filter(
            session =>
              session.task_id == this.taskId &&
              session.ani == this.callflowForm.ani &&
              session.phone_number == this.callflowForm.dnis
          );
          this.sessions.forEach(session => {
            this.$set(this.logs, session.session_id.toString(), []);
          });

          let latestSession = this.sessions.reduce((latest, session) => {
            return latest.timestamp > session.timestamp ? latest : session;
          }, this.sessions[0]);

          this.currentSessionId = latestSession.session_id;
          this.joinLiveCallSessionChannel();
        } else {
          return false;
        }
      });
    }
  },

  beforeDestroy() {
    this.leaveLiveCallTaskChannel();
    this.leaveLiveCallSessionChannel();
  }
};
</script>

<style lang="scss">
$content-theme-color: var(--theme-color) !default;
.live-call-monitoring {
  .el-form-item {
    margin: 15px 10px;
    .el-form-item__label {
      font-size: 14px;
      margin-top: 3px;
    }
    .el-input--small {
      width: 225px;
    }
    .el-input__inner:focus {
      border-color: $content-theme-color;
    }
    .el-button--primary {
      background-color: $content-theme-color;
      border-color: $content-theme-color;
    }
  }

  .el-select.el-select--small {
    width: 90%;
    .el-tag.el-tag--info.el-tag--mini.el-tag--light {
      height: 26px;
    }
    .el-select__tags-text {
      font-size: 13px;
    }
  }

  .el-collapse-item__header {
    position: relative;
    height: auto;
    line-height: 35px;
    display: block;
    padding: 10px;
    .select-node-detail {
      margin-top: 5px;
      width: 200px;
    }
    .timestamp {
      color: #a0a8b5;
      height: 30px;
    }
    .phone {
      color: #181f29;
      height: 30px;
    }
    .el-collapse-item__arrow {
      right: 0px;
      position: absolute;
      bottom: 20px;
    }
    .switch {
      position: absolute;
      top: 20px;
      right: 25px;
      .el-switch__label {
        position: absolute;
        display: none;
        color: #fff !important;
      }
      //turn on switch
      .el-switch__label--right {
        z-index: 1;
        right: -12px;
        span {
          font-size: 13px;
        }
      }
      //turn off switch
      .el-switch__label--left {
        z-index: 1;
        left: 20px;
        span {
          font-size: 13px;
        }
      }
      .el-switch__label.is-active {
        display: block;
        color: $content-theme-color;
      }
      &.el-switch .el-switch__core,
      &.el-switch .el-switch__label {
        width: 75px !important;
      }
      &.el-switch.is-checked .el-switch__core {
        border-color: $content-theme-color;
        background-color: $content-theme-color;
      }
    }
  }
  .el-collapse-item__header .el-collapse-item__content {
    padding-bottom: 0px;
  }

  .call-detail {
    margin-top: 10px;
    min-height: 60px;
    max-height: 450px;
    overflow-y: scroll;
    position: relative;
    .el-loading-mask {
      top: 15px;
      margin-bottom: 30px;
      .el-loading-spinner {
        .el-icon-loading {
          color: $content-theme-color;
        }
        .el-loading-text {
          color: $content-theme-color;
        }
      }
    }
    .el-timeline-item {
      padding-bottom: 5px;
      overflow-x: scroll;
      .el-timeline-item__node--normal {
        left: 0px;
      }
    }

    .highlight {
      .log-event {
        color: $content-theme-color;
      }
      .el-timeline-item__node {
        background-color: $content-theme-color;
      }
    }
  }

  .node-event:last-child {
    .log-event {
      -webkit-animation-duration: 1s;
      -webkit-animation-name: changeColor;
      -webkit-animation-iteration-count: 1;
      animation-duration: 1s;
      animation-name: changeColor;
      animation-iteration-count: 1;
    }
  }

  @-webkit-keyframes changeColor {
    0% {
      color: $content-theme-color;
    }
    90% {
      color: $content-theme-color;
    }
    100% {
      color: #181f29;
    }
  }

  @keyframes changeColor {
    0% {
      color: $content-theme-color;
    }
    95% {
      color: $content-theme-color;
    }
    100% {
      color: #181f29;
    }
  }
  .node-name {
    display: flex;
    align-items: center;
    .node-icon {
      margin-right: 5px;
      width: 24px;
      height: 24px;
    }
  }
}
.svg-icon {
  path {
    fill: #454545;
  }
}
</style>
