<script>
import MessagingNode from "./BaseMessagingNode";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";
import { setupJsonSchemaValidator } from "@/utils/jsonSchemaValidation";

export default {
  mixins: [MessagingNode],
  created() {
    setupJsonSchemaValidator();

    this.$set(
      this.nodeToBind,
      "node_type",
      NODE_TYPES.CONVERSATION_SMS.NODE_TYPE
    );
    this.$set(
      this.nodeToBind.messaging_node.data,
      "sms_node_type",
      "conversation"
    );

    this.$set(this.nodeToBind.messaging_node.data, "message_action_option", 0);

    if (_.isEmpty(this.nodeToBind.messaging_node.data.phone_number)) {
      this.$set(this.nodeToBind.messaging_node.data, "phone_number", "");
    }

    if (_.isEmpty(this.nodeToBind.messaging_node.data.keyword_conditions)) {
      this.$set(this.nodeToBind.messaging_node.data, "keyword_conditions", {});
    }

    if (_.isEmpty(this.nodeToBind.messaging_node.data.variable_rules)) {
      this.$set(this.nodeToBind.messaging_node.data, "variable_rules", {});
    }

    if (_.isEmpty(this.nodeToBind.messaging_node.data.messaging_node_button)) {
      this.$set(
        this.nodeToBind.messaging_node.data,
        "messaging_node_button",
        {}
      );
    }
  }
};
</script>
