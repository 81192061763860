<template>
  <div class="node-value">
    <div class="log-event">
      <div>{{ __("Node Values - ") + nodeName }}</div>
      <el-button
        :icon="
          isShown ? 'el-icon-remove-outline' : 'el-icon-circle-plus-outline'
        "
        @click="handleShowVariable"
      >
      </el-button>
    </div>
    <transition name="fade">
      <pre v-if="isShown" class="node-variable">{{ nodelog }}</pre>
    </transition>
    <br />
  </div>
</template>

<script>
export default {
  props: {
    nodelog: {
      required: true
    },
    nodeName: {}
  },
  data() {
    return {
      isShown: false
    };
  },
  methods: {
    handleShowVariable() {
      this.isShown = !this.isShown;
    }
  }
};
</script>

<style lang="scss">
$content-theme-color: var(--theme-color) !default;
.node-value {
  margin-bottom: -20px;
  .log-event {
    font-weight: bold;
    display: flex;
  }
  .node-variable {
    overflow-x: scroll;
  }
  .el-button {
    border: none;
    font-size: 20px;
    padding: 0px 10px;
    &:hover,
    &:focus {
      color: $content-theme-color;
      background-color: transparent;
    }
  }
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-leave-to {
  opacity: 0;
}
</style>
