<script>
import BaseMessagingNode from "./BaseMessagingNode";
import _ from "lodash";
import { NODE_TYPES } from "@/constants/nodes";

export default {
  name: "ReplyMessageNode",
  mixins: [BaseMessagingNode],
  created() {
    this.$set(this.nodeToBind, "node_type", NODE_TYPES.REPLY_SMS.NODE_TYPE);
    this.$set(this.nodeToBind.messaging_node.data, "sms_node_type", "reply");
    if (_.isEmpty(this.nodeToBind.messaging_node.data.phone_number)) {
      this.$set(this.nodeToBind.messaging_node.data, "phone_number", "");
    }
    this.isPostSendingActionChecked();
  }
};
</script>
