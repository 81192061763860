<template>
  <div v-if="hasPromptOrUtterance(log)">
    <span :style="{ color: ivaOrCustomer === 'IVA' ? 'red' : 'blue' }">{{
      ivaOrCustomer + ": "
    }}</span>
    {{ handlePromptOrUtterance(log) }}
  </div>
</template>

<script>
export default {
  props: {
    log: {
      required: true
    }
  },
  data() {
    return {
      isShown: false,
      ivaOrCustomer: "IVA"
    };
  },
  methods: {
    hasPromptOrUtterance(log) {
      if (log.event === "STUDIO_CONVERSATION") {
        return true;
      }
      return false;
    },

    handlePromptOrUtterance(log) {
      if (log.event === "STUDIO_CONVERSATION") {
        if (log.log_parameters.conversation_from === "Virtual Agent") {
          this.ivaOrCustomer = "IVA";
        }

        if (log.log_parameters.conversation_from === "Customer") {
          this.ivaOrCustomer = "Customer";
        }
      }
      return log.log_parameters.message;
    }
  }
};
</script>

<style lang="scss">
$content-theme-color: var(--theme-color) !default;
</style>
